<template>
	<div id="messages" class="chat-show space-y-4">
		<div class="chat-header">
			<div class="flex items-center">
				<button
					class="btn btn-sm mr-4"
					data-tooltip="Leave Channel"
					data-position="right"
					@click.prevent="onLeaveChannel"
				>
					<div class="btn-ripple"></div>
					<span class="sr-only">Leave channel</span>
					<font-awesome-icon
						class="btn-icon"
						:icon="['fa', 'sign-out']"
						aria-hidden="true"
					/>
				</button>
				<h1 class="chat-title">
					{{ displayName }}
				</h1>
			</div>
			<view-members :members="members" />
		</div>
		<div ref="chat" class="chat-body">
			<chat-message
				v-for="message in messages"
				:key="message.id"
				:message="message"
			/>
		</div>
		<form class="chat-footer" @submit.prevent="onSubmit">
			<button type="button" class="btn mr-2">
				<div class="btn-ripple" />
				<font-awesome-icon
					class="btn-icon"
					:icon="['far', 'paperclip']"
				/>
			</button>
			<input v-model="text" class="message-text" />
			<button type="submit" class="btn ml-2">
				<div class="btn-ripple" />
				<font-awesome-icon
					class="btn-icon"
					:icon="['far', 'paper-plane']"
				/>
			</button>
		</form>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ChatMessage from '@/components/StreamChat/ChatMessage.vue'
import ViewMembers from '@/components/StreamChat/ViewMembers.vue'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		ChatMessage,
		ViewMembers,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the channel's name
		 *
		 * @return  {String}
		 */
		displayName() {
			return this.channelName(
				this.$route.params.id,
				this.activeProfile?.stream_profile_id
			)
		},
		/**
		 * Get the channel's members
		 *
		 * @return  {Array}
		 */
		members() {
			const keys = Object.keys(this.channel?.state.members || {})

			return keys.map(key => this.channel?.state.members[key])
		},
		...mapGetters({
			activeProfile: 'messagingProfiles/activeProfile',
			channelName: 'channels/channelName',
			find: 'channels/find',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Add the stream chat listeners.
		 *
		 * @return  {void}
		 */
		async addListeners() {
			this.channel?.markRead()

			this.newMessageListener = this.channel.on('message.new', () => {
				this.channel?.markRead()
			})

			this.eventListener = this.channel?.on(() => {
				const messageSets = this.channel.state.messageSets

				this.messages = messageSets[0].messages

				this.scrollToBottom()
			})
		},

		async onLeaveChannel() {
			const { stream_profile_id, user } = this.activeProfile

			await this.channel.removeMembers([stream_profile_id], {
				text: `${user.first_name} ${user.last_name} has left the chat.`,
				user_id: stream_profile_id,
			})

			this.remove(this.$route.params.id)

			this.$router.push({
				name: 'app.chat',
			})
		},

		/**
		 * Handle the on route change event.
		 *
		 * @param to
		 * @param from
		 *
		 * @return {void}
		 */
		async onRouteChange(to, from) {
			if (to?.params.id !== from?.params.id) {
				this.channel = this.find(to.params.id)
				this.messages =
					this?.channel?.state?.messageSets[0]?.messages || []

				this.eventListener?.unsubscribe()
				await this.addListeners()
			}

			this.scrollToBottom()
		},

		/**
		 * Scroll to the container's bottom.
		 *
		 * @return  {void}
		 */
		scrollToBottom() {
			if (!this.$refs.chat) {
				return
			}

			this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight
		},

		/**
		 * Handle the on submit form event.
		 *
		 * @return  {void}
		 */
		async onSubmit() {
			try {
				await this.channel.sendMessage({ text: this.text })
				this.text = ''

				this.scrollToBottom()
			} catch (e) {
				this.$alert.error('chat.message.text.error')
			}
		},

		...mapActions({
			remove: 'channels/remove',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Show',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the current route for active partner changes.
		 *
		 * @param {Object} to
		 * @param {Object} from
		 * @return {void}
		 */
		$route: {
			handler: 'onRouteChange',
			immediate: true,
		},
	},

	/**
	 * The component's before destroy lifecycle hook.
	 *
	 * @return {void}
	 */
	beforeDestroy() {
		this.eventListener?.unsubscribe()
		this.newMessageListener?.unsubscribe()
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			channel: null,
			eventListener: null,
			messages: [],
			newMessageListener: null,
			text: null,
		}
	},
}
</script>
