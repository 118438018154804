<template>
	<div
		class="flex flex-row-reverse focus:outline-none"
		:tabindex="show ? '0' : '-1'"
		@blur.self.prevent="close"
	>
		<button
			class="btn btn-outline"
			type="button"
			@click.prevent="show = !show"
		>
			<div class="btn-ripple"></div>
			<span class="btn-label">members ({{ members.length }})</span>
		</button>
		<div v-if="show" class="view-more">
			<div class="view-more-header">
				<span>Members</span>
				<button
					ref="close"
					type="button"
					class="focus:bg-gray-600 focus:outline-none text-black rounded-full h-6 w-6 flex items-center justify-center ml-auto"
					@click="close"
				>
					<span class="sr-only">Close the view more</span>
					<font-awesome-icon :icon="['fal', 'times']" />
				</button>
			</div>
			<div class="view-more-body ">
				<div
					v-for="member in members"
					:key="member.user_id"
					class="calendar-cell-content"
				>
					<div class="flex flex-col">
						<span class="font-bold">
							{{ member.user.name }}
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Emit the close event to any parent listeners.
		 *
		 * @return {void}
		 */
		close() {
			if (this.show) {
				this.show = false
			}
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'ViewMembers',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The events to be displayed.
		 *
		 * @type {Object}
		 */
		members: {
			type: Array,
			required: true,
		},
	},

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Focus on the outter element when displaying the pop-up.
		 *
		 * @return {void}
		 */
		show() {
			if (this.show) {
				this.$el.focus()
			}
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			show: false,
		}
	},
}
</script>
