<template>
	<div class="message">
		<div v-if="isOwnMessage" class="flex items-end justify-end">
			<div class="flex flex-col space-y-2 max-w mx-2 order-1 items-end">
				<div
					class="px-4 py-2 rounded-lg rounded-br-none bg-blue-600 text-white"
				>
					<div class="message-header">
						<h2>You</h2>
					</div>
					<div class="message-body" v-html="message.html"></div>
					<div class="message-footer">
						{{ message.created_at | toDateTime }}
					</div>
				</div>
			</div>
			<img
				:src="message.user.image"
				alt="profile"
				class="w-12 h-12 rounded-full order-2"
			/>
		</div>
		<div v-else class="flex items-end">
			<div class="flex flex-col space-y-2 max-w mx-2 order-2 items-start">
				<div
					class="px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-300 text-gray-600"
				>
					<div class="font-bold">
						<h2>{{ message.user.name }}</h2>
					</div>
					<div class="message-body" v-html="message.html"></div>
					<div class="message-footer">
						{{ message.created_at | toDateTime }}
					</div>
				</div>
			</div>
			<img
				:src="message.user.image"
				alt="profile"
				class="w-12 h-12 rounded-full order-1"
			/>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Determine if the message is from the current user.
		 *
		 * @return  {Boolean}
		 */
		isOwnMessage() {
			return (
				this.message.user.id === this.activeProfile?.stream_profile_id
			)
		},

		...mapGetters({
			activeProfile: 'messagingProfiles/activeProfile',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'ChatMessage',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The chat message.
		 *
		 * @type {Object}
		 */
		message: {
			type: Object,
			required: true,
		},
	},
}
</script>
